<template>
  <div>
    <van-nav-bar
      title="欢迎使用中控大师「合伙人」系统"
    />
    <van-nav-bar title="注册账号" />
    <van-form>
      <van-field
        v-model.trim="phone"
        name="手机号码"
        label="手机号码"
        placeholder="手机号码"
      />
      <van-field
        v-model.trim="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
      />
      <van-field
        v-model.trim="password2"
        type="password"
        name="重复密码"
        label="重复密码"
        placeholder="再次输入密码"
      />

      <van-field
        v-model.trim="invitation_code"
        name="注册邀请码"
        label="注册邀请码"
        placeholder="注册邀请码"
      />

      <van-field
        v-model.trim="sms"
        name="短信验证码"
        label="短信验证码"
        placeholder="短信验证码"
      >
        <van-button
          slot="button"
          size="small"
          type="primary"
          @click="getVerifyCode"
          >{{ getCodeTitle }}</van-button
        >
      </van-field>

      <div class="my_tips">
        <b>用户须知：</b><br />
        1.获取注册邀请码，请关注中控大师微信公众号: HondaMaster，回复“合伙人”<br />
        2.注册邀请码不能重复使用，注册完成即失效<br />
      </div>
      <div style="margin: 16px">
        <van-button round block type="info" @click="doRegister"
          >注册</van-button
        >
      </div>

      <div style="margin: 16px">
        <van-row>
          <van-col span="24"
            ><router-link to="/member/login"
              >已有账号？去登录</router-link
            ></van-col
          >
        </van-row>
      </div>
    </van-form>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";
export default {
  data() {
    return {
      phone: "",
      password: "",
      password2: "",
      invitation_code: "",
      sms: "",
      getCodeTitle: "获取验证码",
      isClick: true,
    };
  },
  components: {
    CopyRight,
  },
  methods: {
    getVerifyCode() {
      if (!this.isClick) {
        return;
      }

      if (!this.phone) {
        this.$toast.fail("请填写手机号码");
        return;
      }
      if (!this.phone.match(/^1[3-9]\d{9}$/)) {
        this.$toast.fail("手机号码格式错误");
        return;
      }

      if (!this.password) {
        this.$toast.fail("请填写密码");
        return;
      }

      if (this.password.length < 6) {
        this.$toast.fail("密码必须大于等于6位");
        return;
      }

      if (!this.password2) {
        this.$toast.fail("请填写重复密码");
        return;
      }

      if (this.password != this.password2) {
        this.$toast.fail("两次输入密码不一致");
        return;
      }

      if (!this.invitation_code) {
        this.$toast.fail("请填写注册邀请码");
        return;
      }

      let postData = this.$qs.stringify({
        phone: this.phone,
        invitation_code: this.invitation_code,
      });
      axios
        .post("api/send_sms", postData)
        .then((res) => {
          if(res.data.code == 0){
            this.validateBtn();
            this.$toast("发送短信成功，请注意查看手机");
          }else{
            this.$toast(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.isClick = true;
          this.getCodeTitle = "获取验证码";
        } else {
          this.getCodeTitle = time + "秒后重试";
          this.isClick = false;
          time--;
        }
      }, 1000);
    },
    doRegister() {
      if (!this.phone) {
        this.$toast.fail("请填写手机号码");
        return;
      }
      if (!this.phone.match(/^1[3-9]\d{9}$/)) {
        this.$toast.fail("手机号码格式错误");
        return;
      }

      if (!this.password) {
        this.$toast.fail("请填写密码");
        return;
      }

      if (this.password.length < 6) {
        this.$toast.fail("密码必须大于等于6位");
        return;
      }

      if (!this.password2) {
        this.$toast.fail("请填写重复密码");
        return;
      }

      if (this.password != this.password2) {
        this.$toast.fail("两次输入密码不一致");
        return;
      }

      if (!this.sms) {
        this.$toast.fail("请填写短信验证码");
        return;
      }

      if (!this.invitation_code) {
        this.$toast.fail("请填写注册邀请码");
        return;
      }

      let postData = this.$qs.stringify({
        phone: this.phone,
        password: this.password,
        password2: this.password2,
        sms: this.sms,
        invitation_code: this.invitation_code,
      });

      axios
        .post("api/member/register", postData)
        .then((res) => {
          if (res.data.code == 0) {
            this.$toast("注册成功");
            this.$router.push("/member/login");
          } else {
            this.$toast(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.fail("注册失败");
        });
    },
  },
};
</script>
